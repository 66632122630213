import React, { useState } from "react";
import moment from 'moment'
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { Fade } from "react-awesome-reveal";

import { labels, localeUrls, days } from '../langs/langs'

import ArrowLink, { rSVG } from "./ArrowLink"

import calendarI from '../images/calendar.svg'
import crossI from '../images/cross.svg'

const NewsCon = ({ data, localeID }) => {

    const news = data.nodes;

    const emptyMsg = <div><h3 className="empty">{labels[localeID]["WEARESORRY"]}</h3><p className="empty">{labels[localeID]["NOTHINGNOW"]}</p></div>;

    const [modalOpened, setModalOpened] = useState(false);
    const [modalData, setModalData] = useState({});

    return (
        <>
            <NewsModal item={modalData} opened={modalOpened} close={()=>setModalOpened(false)}/>
            <div className="hPadSec concrete s">
                <div className="content">
                    <h2 className="b">{labels[localeID]["NEWS"]}</h2>
                    <div className="squareGrid">
                        
                        {!!news.length ? news.map((item, index)=>{
                                return <NewsCard item={item} key={index} localeID={localeID} onMore={()=>{setModalData(item);setModalOpened(true);}}/>
                            }) : emptyMsg}
                    </div>
                    <Fade>
                        <ArrowLink to={localeUrls[localeID]["NEWS"]} className="big" color="#4C9D34">{labels[localeID]["SHOWALLNEWS"]}</ArrowLink>
                    </Fade>
                </div>
            </div>
        </>
    )
}

export const NewsCard = ({ item, localeID, onMore }) => <div className="shadow">
    <div className="imgCon16">
        <GatsbyImage image={item?.featuredImage?.node?.localFile?.childrenImageSharp[0]?.gatsbyImageData} alt='test' />
    </div>
    <div className="infoBox center">
        <div>
            <p className="center"><img src={calendarI}/>
                <span className="oh">{item.date} | {days(localeID)[moment(item.dateBasicFormat).day()]}</span>
            </p>
            <h3>{item.title}</h3>
            <a onClick={onMore} className="aArow" style={{color:"#ED6E47"}} color="#ED6E47">{labels[localeID]["MORE"]} {rSVG("#ED6E47")}</a>
        </div>
    </div>
</div>

export const NewsModal = ({ item, opened, close }) => {
    if (Object.keys(item).length === 0) return <></>;
    return (<div id="modalGallery" className={"center " + (opened ? 'open' : '')}>
        <div className="shadow">
            <p className="center"><img src={calendarI}/>
                <span className="oh">{item.date}</span>
                <span className="fillFlex"/>
                <button onClick={close} className="center"><img src={crossI}/></button>
            </p>
            <div className="imgCon16">
                <GatsbyImage image={item?.featuredImage?.node?.localFile?.childrenImageSharp[0]?.gatsbyImageData} alt='test' />
            </div>
            <div className="infoBox">
                <h3>{item.title}</h3>
                {parse((!!item.content ? item.content : ''))}
            </div>
        </div>
    </div>)
}


export default NewsCon;
